// App Component
import React from "react";
import { Helmet } from "react-helmet";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import MainController from "./components/MainController";

function App() {
  injectStyle();
  return (
    <>
      <Helmet titleTemplate="%s - Whelen - AOG Admin Console" defaultTitle="Whelen - AOG Admin Console">
        <meta name="description" content="AOG Admin Console" />
      </Helmet>
      <MainController />
      <ToastContainer />
    </>
  );
}

export default App;
