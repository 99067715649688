// Navigation Component
import React, { Component } from "react";
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";

// Services
import AuthService from "../../services/AuthService";
import ChangePassword from "../Auth/ChangePassword";
import configData from "../../config/config";
import ChangesWarningModal from "./ChangesWarningModal";

const { INFO_MESSAGE } = configData;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      ActiveClass: false,
      openModal: false,
      changeRequest: false,
      action: "",
      page: "",
    };
  }

  componentDidMount() {
    if (
      window.location.pathname === "/users" ||
      window.location.pathname === "/configurations" ||
      window.location.pathname === "/history"
    ) {
      this.setState({ page: window.location.pathname.replace("/", "") });
    } else {
      this.setState({ page: "users" });
    }
    window.onpopstate = () => null;
  }

  toggleMenu = (idx) => {
    this.setState({ addClass: idx });
  };

  AccordionToggle = (idx) => {
    this.setState({ ActiveClass: idx });
  };

  // User clicks on Log out button
  onSignOut = () => {
    AuthService.logout()
      .then(() => {
        this.reset();
      })
      .catch(() => {
        this.reset();
      });
  };

  // Redirect app to login page and clean the local storage
  reset = () => {
    const { history } = this.props;
    history.replace("/login");
    window.onpopstate = () => {
      history.go(1);
    };
    localStorage.clear();
    localStorage.setItem("loggedIn", JSON.stringify(false));
  };

  // used for open change-password modal
  openChangePasswordModal = () => {
    this.setState({
      openModal: true,
    });
  };

  // used for close change-password modal
  closeChangePasswordModal = () => {
    this.setState({
      openModal: false,
    });
  };

  checkNav = (e, page) => {
    e.preventDefault();
    const isDirty = AuthService.getIsDirty();
    if (isDirty) {
      this.setState({
        changeRequest: true,
        action: page,
      });
    } else if (page === "logout") {
      this.setState({ page });
      this.onSignOut();
    } else {
      this.setState({ page });
      const { history } = this.props;
      history.push(`/${page}`);
    }
  };

  closeChangesWarningModal = (status) => {
    const { history } = this.props;
    const { action } = this.state;

    if (status) {
      this.setState({
        changeRequest: false,
      });
    } else {
      this.setState({
        changeRequest: false,
        page: action,
      });
      sessionStorage.setItem("isDirty", JSON.stringify(false));
      switch (action) {
        case "logout":
          this.onSignOut();
          break;
        case "users":
          history.push(`/${action}`);
          break;
        case "configurations":
          history.push(`/${action}`);
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { handleSidebarToggle } = this.props;
    const { openModal, changeRequest, page } = this.state;
    return (
      <>
        <Modal
          className="saveasModal"
          backdrop="static"
          tabindex="-1"
          show={changeRequest}
          onHide={this.closeChangesWarningModal}
          centered
        >
          <ChangesWarningModal
            closeChangesWarningModal={this.closeChangesWarningModal}
            message={INFO_MESSAGE.UNSAVED_CHANGES_CONFM}
          />
        </Modal>
        <nav id="sidebar" className="sidebar-wrapper bg-gray-darker">
          <div className="d-none">
            <div className="d-flex px-5 sidebar-brand">
              <button
                type="button"
                aria-label="button"
                className="menu-trigger link-button mr-4"
                onClick={handleSidebarToggle}
              >
                <span />
                <span />
                <span />
              </button>
            </div>
          </div>
          <div className="sidebar-content d-flex">
            <div className="sidebar-menu flex-grow-1">
              <ul className="d-flex flex-grow-1 flex-column">
                <li>
                  <a
                    href="#"
                    className={page === "users" && !openModal ? "active" : ""}
                    onClick={(e) => this.checkNav(e, "users")}
                  >
                    <i className="fas fa-fw fa-user" />
                    <span>Manage Users</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={page === "history" && !openModal ? "active" : ""}
                    onClick={(e) => this.checkNav(e, "history")}
                  >
                    <i className="fas fa-fw fa-clock" />
                    <span>Sharing History</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={page === "configurations" && !openModal ? "active" : ""}
                    onClick={(e) => this.checkNav(e, "configurations")}
                  >
                    <i className="fas fa-fw fa-database" />
                    <span>Manage Compliance Data</span>
                  </a>
                </li>
                <li>
                  <a href="#" className={openModal ? "active" : ""} onClick={this.openChangePasswordModal}>
                    <i className="fas fa-fw fa-lock" />
                    <span>Change Password</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={page === "logout" && !openModal ? "active" : ""}
                    onClick={(e) => this.checkNav(e, "logout")}
                  >
                    <i className="fas fa-fw fa-sign-out-alt" />
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <Modal
            className="change-password-modal"
            centered
            backdrop="static"
            keyboard={false}
            show={openModal}
            onHide={this.closeChangePasswordModal}
          >
            <ChangePassword closeChangePasswordModal={this.closeChangePasswordModal} />
          </Modal>
        </nav>
      </>
    );
  }
}

export default withRouter(Navigation);
