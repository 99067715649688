/* eslint-disable react/no-array-index-key */
// Pdf Components
import React from "react";

const ONTARIO_6_1 = "OPLA & ERV 6.1";

const Pdf = ({ tableList, pdfDetails }) => (
  <div className="pdf">
    <page size="A4">
      <div className="p-3 my-2">
        {tableList.map((intensity, ind) =>
          intensity.length ? (
            <div
              className={`card-body p-0 ${
                ind === 1 && intensity.length > 4 ? (intensity.length === 7 ? "mg-top-7" : "mg-top-8") : ""
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-3" />
                <div className="col-md-6 text-center">
                  <div className="font-weight-bold justify-content-center font-5">
                    {ONTARIO_6_1 === pdfDetails.standard
                      ? `Primary Emergency`
                      : `${ind ? "Blocking" : "Calling"} for Right-of-Way`}
                  </div>
                </div>
              </div>
              <div className="line" />
              <div className="row">
                <div className="col-md-6">
                  <div className="font-weight-bold ">{`Standard - ${pdfDetails.standard}`}</div>
                  <div className="font-weight-bold ">
                    {" "}
                    Mode -
                    {ONTARIO_6_1 === pdfDetails.standard
                      ? ` Primary Emergency`
                      : ` ${ind ? "Blocking" : "Calling"} for Right-of-Way`}
                  </div>
                </div>
                <div className="col-md-6 text-right">
                  <div className="font-weight-bold">{`Configuration Name - ${pdfDetails.name}`}</div>
                  <div className="font-weight-bold text-right">{`Created By - ${pdfDetails.emailId}`}</div>
                </div>
              </div>
              <div className="line" />
              <div className="row">
                <div className="col-md-12">
                  <div className="font-weight-bold">Data Information</div>
                  <div className="">
                    - Photometric data points are provided in CdS/Min. at 40% duty cycle, in accordance with
                    {` ${pdfDetails.standard}`}
                  </div>
                  <div className="">- Data is calculated from all lightheads counted in each zone </div>
                </div>
              </div>
              <div className="line" />
              <div className="font-weight-bold mb-2">Data Points</div>
              <div className="row justify-content-center">
                <div className="col-md-12">
                  {intensity.map((table) => (
                    <div>
                      <div className="d-flex heading-pdf">
                        <div className="font-weight-bold">
                          Zone
                          {` ${table?.info?.zone} ${
                            table?.info?.level && table?.info?.level !== "single" ? table?.info?.level : ""
                          }`}
                        </div>
                        <div className="font-weight-bold text-center">
                          <i>Angle - Horizontal</i>
                        </div>
                        <div className="font-weight-bold text-right">
                          <i>Totals:</i>
                        </div>
                      </div>
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <table className="tbl">
                            <thead>
                              <tr className="text-center">
                                {table.header.map((header, idx) => (
                                  <th className="border-0 text-center font-weight-bold" key={idx}>
                                    <div className="headings">{header}</div>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {table.rows.map((row, index) => (
                                <tr className="text-center" key={index}>
                                  {row.map((data, i) => (
                                    <td className="text-right" key={i}>
                                      <div className={`comn-row ${i === 0 ? "font-weight-bold" : ""}`}>{data}</div>
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="total-heading">
                          <div className="d-flex justify-content-end flex-column">
                            <div className="d-flex justify-content-end">
                              <span className="comn-row text-right prf-1">H Total</span>
                              <span className="comn-row text-right prf-val">
                                {new Intl.NumberFormat().format(table?.info?.totalH)}
                              </span>
                            </div>
                            <div className="text-center d-flex justify-content-end">
                              <span className="comn-row prf-1 text-right">Min at any H Point</span>
                              <span className="comn-row text-right prf-val">
                                {new Intl.NumberFormat().format(table?.info?.minH)}
                              </span>
                            </div>
                            <div className="text-center d-flex justify-content-end">
                              <span className="comn-row text-right prf-1">Min at any Point ±5° from H</span>
                              <span className="comn-row text-right prf-val">
                                {new Intl.NumberFormat().format(table?.info?.minAtAnyPointH)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null
        )}
      </div>
    </page>
    <div
      className={`${
        tableList[0].length < 5 || pdfDetails.standard === "OPLA & ERV 5.0" || pdfDetails.standard === ONTARIO_6_1
          ? "cust-footer-single"
          : "cust-footer-double"
      }`}
    >
      <div className="date-margin">
        <div className="text-right mt-3 d-flex flex-column">
          <div className="d-flex flex-wrap align-items-baseline justify-content-end">
            <div className="d-flex ">
              <p className="font-weight-bold">Dept: </p>
              <p className="text-left d-flex">{pdfDetails?.department}</p>
            </div>
            <div className="ml-2 d-flex ">
              <p className="font-weight-bold">Job: </p>
              <p className="text-left d-flex">{pdfDetails?.job}</p>
            </div>
            <div className="ml-2 d-flex ">
              <p className="font-weight-bold">Contract: </p>
              <p className="text-left d-flex">{pdfDetails?.contract}</p>
            </div>
            <div className="ml-2 d-flex ">
              <p className="font-weight-bold">VIN: </p>
              <p className="text-left d-flex">{pdfDetails?.vin}</p>
            </div>
            <div className="ml-2 d-flex ">
              <p className="font-weight-bold d-block" style={{ minWidth: "25px" }}>
                Generated By:
              </p>
              <p className="text-left d-flex">{pdfDetails?.user}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">{pdfDetails?.date}</div>
      <div className="line ml-4" />

      <div className="text-center">
        <div className="footerText ml-7 mt-1">{pdfDetails.footerText}</div>
      </div>
    </div>
  </div>
);

export default Pdf;
