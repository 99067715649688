// Confirmation modal component
import React from "react";

const ConfirmationModal = ({ closeConfirmationModal, message, changing }) => {
  // used for call parent function for the save as
  const onClickOk = () => {
    closeConfirmationModal(true);
  };
  const closeModal = () => {
    closeConfirmationModal(false);
  };

  return (
    <>
      <div id="confirm-modal" className="m-auto">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button
                type="button"
                className="close"
                aria-label="close modal"
                data-dismiss="modal"
                onClick={closeModal}
              >
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h4 className="modal-title text-white">Change User Status</h4>
            <div className="rotation-option" style={{ marginTop: "30px" }}>
              <div className="form-group">
                <div style={{ color: "white" }}>{message}</div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-red" onClick={onClickOk}>
                {changing && <span className="spinner-border spinner-border-sm mr-1" />}
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
