// component for change password
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import jwt from "jwt-decode";

// Services
import AuthService from "../../services/AuthService";
import commonUtiles from "../../services/CommonUtiles";

import Config from "../../config/config";

const warningMessagePassword =
  "Use 8 or more characters with a combination of upper and lower case letters, numbers & symbols";

const ChangePassword = ({ closeChangePasswordModal, token, uid, isForceLogin }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [userName, setUserName] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [isSetPassword, setIsSetPassword] = useState(false);

  const getUserName = () => setTimeout(() => setUserName(AuthService.getUserName()), 1000);
  const getAccessToken = () => setTimeout(() => setAccessToken(AuthService.getAccessToken()), 1000);

  const { SUCCESS_MESSAGES } = Config;

  // form validation rules
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().trim().required("Current password is required"),
    newPassword: Yup.string()
      .trim()
      .required("New password is required")
      .min(8, warningMessagePassword)
      .matches(RegExp("(.*[a-z].*)"), warningMessagePassword)
      .matches(RegExp("(.*[A-Z].*)"), warningMessagePassword)
      .matches(RegExp("(.*\\d.*)"), warningMessagePassword)
      .matches(RegExp("[!@#$%^&*(),.?\":{}|<>';/_~\\-\\=+\\\\/`/]"), warningMessagePassword)
      .max(
        256,
        "Maximum 256 characters with a combination of upper and lower case letters, numbers & symbols are allowed."
      ),
    confirmPassword: Yup.string()
      .trim()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null, ""], "Confirm password does not match new password"),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, setValue, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (token && uid) {
      setIsSetPassword(true);
      setValue("currentPassword", uid);
    }
    getUserName();
    getAccessToken();
  }, []);

  // User to call change password API, clicked on submit.
  const onChangePassword = (data) => {
    setIsLoading(true);
    const request = {
      userName,
      accessToken,
      currentPassword: data.currentPassword.trim(),
      newPassword: data.newPassword.trim(),
    };
    setIsHidden(true);
    AuthService.changePassword(request)
      .then(() => {
        commonUtiles.displayNotification(SUCCESS_MESSAGES.PASSWORD_UPDATED, "success");
        setIsLoading(false);
        closeChangePasswordModal();
      })
      .catch((error) => {
        commonUtiles.displayErrorMessage(error);
        setIsLoading(false);
      });
  };
  // User to call set password API, clicked on submit.
  const onSetPassword = (data) => {
    setIsLoading(true);
    const request = {
      userId: uid,
      token,
      password: data.newPassword.trim(),
      changePassword: isForceLogin || false,
    };
    setIsHidden(true);
    AuthService.setPassword(request)
      .then((res) => {
        const authData = {
          email: res.data.userId,
          password: request.password,
        };
        // user login service
        AuthService.login(authData)
          .then((response) => {
            setIsLoading(false);
            const resData = response.data;
            // decode idToken
            const decoded = jwt(resData.idToken);
            Object.keys(decoded).forEach((key) => {
              if (key.includes(":")) {
                const [, custumAttribute] = key.split(":");
                resData[custumAttribute] = decoded[key];
              } else if (key === "family_name") {
                resData.lastName = decoded.family_name;
              } else if (key === "name") {
                resData.firstName = decoded.name;
              } else if (key === "sub") {
                resData.userId = decoded.sub;
              } else {
                resData[key] = decoded[key];
              }
            });
            AuthService.setLocalStorage(resData, authData);
            closeChangePasswordModal();
            commonUtiles.displayNotification(SUCCESS_MESSAGES.PASSWORD_SET, "success");
            history.push("/users");
          })
          .catch((error) => {
            commonUtiles.displayErrorMessage(error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        closeChangePasswordModal();
        commonUtiles.displayErrorMessage(error);
        setIsLoading(false);
      });
  };
  // uses to call the Chnage Password/Set Password API, clicked on submit button
  const onSubmit = (data) => {
    if (isSetPassword) {
      onSetPassword(data);
    } else {
      onChangePassword(data);
    }
  };

  const symbols = "(= + - ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ )`";
  return (
    <>
      <ToastContainer />
      <div className="mg-left">
        <div className="changepassword">
          <div className="changepass-block">
            <div className="d-flex align-items-center changepass-title">
              <h2 className="text-white mb-0 change-password-title">
                {!isSetPassword ? "Change Password" : "Set New Password"}
                <i
                  style={{ fontSize: "initial" }}
                  className="fa fas fa-question-circle text-white mb-5 ml-2"
                  data-tip={`A new password must fulfill below criteria <br/ > <br/ >
                1) Minimum length of 8 characters <br/ >
                2) At least one uppercase, or capital letter (ex: A, B, etc.) <br/ >
                3) At least one lowercase letter (ex: a, b, etc.) <br/ >
                4) At least one number (ex: 0,1, etc.) <br/ >
                5) At least one non-alphanumeric symbol ${symbols}. <br/ > <br/ >
                Please note that password is case sensitive.`}
                  data-html
                  data-for="changepassword"
                />
              </h2>
              <ReactTooltip id="changepassword" place="right" multiline type="light" effect="solid" />
              {!isSetPassword ? (
                <div className="close-icon text-right mb-5">
                  <button
                    type="button"
                    className="close"
                    aria-label="changepassword"
                    data-dismiss="modal"
                    onClick={closeChangePasswordModal}
                  >
                    <span aria-hidden="true">
                      <i className="fa fa-times text-red" />
                    </span>
                  </button>
                </div>
              ) : null}
            </div>
            <form className="changepass-form" action="" method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className={`row user-fg ${isSetPassword ? "display-none" : ""}`}>
                <label className="col-sm-5 col-form-label">Current Password</label>
                <div className="col-sm-7 pos-relative mb-25">
                  <input
                    type={isHidden ? "password" : "text"}
                    name="currentPassword"
                    ref={register}
                    className={`form-control ${errors.currentPassword ? "is-invalid" : ""} user-input`}
                    autoComplete="off"
                    maxLength={256}
                  />
                  {errors.currentPassword && (
                    <div className="invalid-feedback d-flex">{errors.currentPassword.message}</div>
                  )}
                </div>
              </div>
              <div className="row user-fg">
                <label className="col-sm-5 col-form-label">New Password</label>
                <div className="col-sm-7 pos-relative mb-25">
                  <input
                    type={isHidden ? "password" : "text"}
                    name="newPassword"
                    ref={register}
                    className={`form-control ${errors.newPassword ? "is-invalid" : ""} user-input`}
                    autoComplete="off"
                    maxLength={256}
                  />
                  {errors.newPassword && <div className="invalid-feedback d-flex">{errors.newPassword.message}</div>}
                </div>
              </div>
              <div className="row user-fg">
                <label className="col-sm-5 col-form-label">Confirm Password</label>
                <div className="col-sm-7 pos-relative mb-25">
                  <input
                    type={isHidden ? "password" : "text"}
                    name="confirmPassword"
                    ref={register}
                    className={`form-control ${errors.confirmPassword ? "is-invalid" : ""} user-input`}
                    autoComplete="off"
                    maxLength={256}
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback d-flex">{errors.confirmPassword.message}</div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center mb-10">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-red mt-0 mr-3 ${isLoading ? "cursor-auto" : ""}`}
                >
                  {isLoading ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
