// SetPassword Component
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import ChangePassword from "./ChangePassword";

const SetPassword = ({ location }) => {
  const [openModal, setModalVisibility] = useState(false);
  const [token, setToken] = useState("");
  const [uid, setUid] = useState("");

  // used for open change-password modal
  const openSetPasswordModal = () => {
    setModalVisibility(true);
  };

  // used for close change-password modal
  const closeSetPasswordModal = () => {
    setModalVisibility(false);
  };

  // Function is used to get config data on page load.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get("token"));
    setUid(params.get("uid"));
    openSetPasswordModal();
  });

  return (
    <>
      {token && uid ? (
        <Modal
          className="set-password"
          centered
          backdrop="static"
          keyboard={false}
          show={openModal && !!token && !!uid}
          onHide={closeSetPasswordModal}
        >
          <ChangePassword closeChangePasswordModal={closeSetPasswordModal} token={token} uid={uid} />
        </Modal>
      ) : (
        <div className="notFound">Page Not Found ...</div>
      )}
    </>
  );
};

export default SetPassword;
