import axios from "axios";
import configData from "../config/config";

// Method use to make api request to aws
const getShareHistory = async function (searchString = "", pageSize, pageToken = "") {
  let url = `${configData.API_URL}/copyconfigs?email=${searchString}&pageSize=${pageSize}`;
  if (pageToken) {
    url = `${url}&id=${pageToken}`;
  }
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

const ShareHistoryService = {
  getShareHistory,
};

export default ShareHistoryService;
