// Header Component
import React, { useEffect, useState } from "react";

// Ui Components
import Navbar from "react-bootstrap/Navbar";
import WhelenSymbol from "../../assets/img/logo.svg";
import UserIcon from "../../assets/img/UserProfile.png";
import authService from "../../services/AuthService";

export default ({ handleSidebarToggle }) => {
  const [name, setName] = useState("");

  const getName = () => setTimeout(() => setName(authService.getName()), 1000);

  useEffect(() => {
    getName();
  }, []);

  return (
    <>
      <Navbar className="header bg-gray-darker py-1 fixed-header">
        <div className="container-fluid gutters-0">
          <div className="d-flex col ml-md-auto align-items-center">
            <button
              type="button"
              className="menu-trigger mr-4 link-button"
              aria-label="button"
              onClick={handleSidebarToggle}
            >
              <span />
              <span />
              <span />
            </button>
            <img src={WhelenSymbol} alt="" className="w-7" />
            <span className="h4 mb-0 font-weight-bold d-none d-sm-inline-block mr-auto">AOG Admin Console</span>
          </div>
          <div className="ml-auto">
            <div className="nav-link pr-0 leading-none" style={{ cursor: "default" }}>
              <span className="d-none d-lg-block" style={{ width: "min-content" }}>
                <span className="text-white">{name}</span>
              </span>
              <span className="ml-2 avatar">
                <img className="img-profile rounded-circle" alt="" src={UserIcon} />
              </span>
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};
