// Main Component
import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import Modal from "react-bootstrap/Modal";

// Route Common Components
import { withRouter } from "react-router";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import Navigation from "./Common/Navigation";

// Route AdminConsole Components
import UserList from "./Pages/UserManagement/UserList";
import CreateUpdateUser from "./Pages/UserManagement/CreateUpdateUser";
import ConfigurationList from "./Pages/ConfigurationManagement/ConfigurationList";
import Login from "./Auth/Login";
import authService from "../services/AuthService";
// eslint-disable-next-line no-unused-vars
import AuthInterceptor from "../interceptors/AuthInterceptor";
import SetPassword from "./Auth/SetPassowrd";
import NotFound from "./Common/NotFound";
import SessionTimeoutModal from "./Common/SessionTimeoutModal";
import config from "../config/config";
import ShareHistory from "./Pages/History/ShareHistory";

class MainController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: true,
      showTimeoutModal: false,
    };
    this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
    this.sessionTimer = null;
    this.logoutTimer = null;
  }

  // Added event listener to notify any change in localstorage varible value change
  componentDidMount() {
    window.onpopstate = () => null;
    if (typeof window !== "undefined") {
      window.addEventListener("storage", (event) => {
        const { history } = this.props;
        const page = JSON.parse(JSON.stringify(window.location.href.split("/").pop()));
        if (event.key === "loggedIn" && event.newValue === "false") {
          this.resetAll();
          history.replace("/login");
          window.onpopstate = () => {
            history.go(1);
          };
          if (page !== "" && !page.includes("login")) window.location.reload();
        }
      });
      window.addEventListener("mouseover", () => {
        const { showTimeoutModal } = this.state;
        if (authService?.isUserLoggedin() && !showTimeoutModal && !this.logoutTimer) {
          this.preSessionStart();
        }
      });
      window.addEventListener("keydown", () => {
        const { showTimeoutModal } = this.state;
        if (authService?.isUserLoggedin() && !showTimeoutModal && !this.logoutTimer) {
          this.preSessionStart();
        }
      });
    }
  }

  handleSidebarToggle() {
    this.setState((prevState) => ({
      toggled: !prevState.toggled,
    }));
  }

  // Check weather user is logged in or not, before giving access to any API request
  requireLogin = (to, from, next) => {
    if (to.meta.auth) {
      if (authService.isUserLoggedin()) {
        this.preSessionStart();
        next();
      } else {
        this.resetAll();
        next.redirect("/login");
      }
    } else {
      next();
    }
  };

  reset = () => {
    const { history } = this.props;
    history.replace("/login");
    window.onpopstate = () => {
      history.go(1);
    };
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("loggedIn", JSON.stringify(false));
    window.location.reload();
  };

  // User clicks on Log out button
  onSignOut = () => {
    authService
      .logout()
      .then(() => {
        this.reset();
      })
      .catch(() => {
        this.reset();
      });
  };

  resetAll = () => {
    clearTimeout(this.sessionTimer);
    clearTimeout(this.logoutTimer);
    this.setState({ showTimeoutModal: false });
  };

  startSession = () => {
    if (authService.isUserLoggedin()) {
      const time = config.SESSION_TIMEOUT * 60000;
      this.sessionTimer = setTimeout(() => {
        if (authService.isUserLoggedin()) {
          this.setState({ showTimeoutModal: true });
        }
      }, time);
    } else {
      this.resetAll();
    }
  };

  resetTimer = () => {
    this.resetAll();
    this.startSession();
  };

  preSessionStart = () => {
    const { showTimeoutModal } = this.state;
    if (!showTimeoutModal && !this.logoutTimer) {
      this.resetTimer();
    } else {
      this.setState({ showTimeoutModal: false });
    }
  };

  onResetSession = () => {
    this.setState({ showTimeoutModal: false });
    this.resetTimer();
  };

  onCancleSession = (remainingCount) => {
    if (remainingCount) {
      const timeRemaining = remainingCount * 1000;
      this.logoutTimer = setTimeout(() => {
        this.onSignOut();
        this.resetAll();
      }, timeRemaining);
    }
    if (remainingCount === 0) {
      this.resetAll();
      this.onSignOut();
    }
    this.setState({ showTimeoutModal: false });
  };

  render() {
    const { toggled, showTimeoutModal } = this.state;
    return (
      <Router>
        <GuardProvider guards={[this.requireLogin]}>
          <Switch>
            <GuardedRoute exact path="/" component={Login} />
            <GuardedRoute exact path="/login" component={Login} />
            <GuardedRoute exact path="/users">
              <main className={`page theme-dark ${toggled ? "" : "toggled"}`}>
                <Header handleSidebarToggle={this.handleSidebarToggle} />
                <div className="page-wrapper">
                  <Navigation handleSidebarToggle={this.handleSidebarToggle} />
                  <div className="page-scroll">
                    <div className="flex-fill page-fix">
                      <div className="m-3">
                        <div className="container-fluid">
                          <GuardedRoute exact path="/users" component={UserList} meta={{ auth: true }} />
                        </div>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </main>
            </GuardedRoute>
            <GuardedRoute exact path="/users/createuser">
              <main className={`page theme-dark ${toggled ? "" : "toggled"}`}>
                <Header handleSidebarToggle={this.handleSidebarToggle} />
                <div className="page-wrapper">
                  <Navigation handleSidebarToggle={this.handleSidebarToggle} />
                  <div className="page-scroll">
                    <div className="flex-fill page-fix">
                      <div className="m-3">
                        <div className="container-fluid">
                          <GuardedRoute path="/users/createuser" component={CreateUpdateUser} meta={{ auth: true }} />
                        </div>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </main>
            </GuardedRoute>
            <GuardedRoute exact path="/users/edit/:id">
              <main className={`page theme-dark ${toggled ? "" : "toggled"}`}>
                <Header handleSidebarToggle={this.handleSidebarToggle} />
                <div className="page-wrapper">
                  <Navigation handleSidebarToggle={this.handleSidebarToggle} />
                  <div className="page-scroll">
                    <div className="flex-fill page-fix">
                      <div className="m-3">
                        <div className="container-fluid">
                          <GuardedRoute
                            path="/users/edit/:id"
                            render={(props) => <CreateUpdateUser id={props.match.params.id} meta={{ auth: true }} />}
                          />
                        </div>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </main>
            </GuardedRoute>
            <GuardedRoute exact path="/history">
              <main className={`page theme-dark ${toggled ? "" : "toggled"}`}>
                <Header handleSidebarToggle={this.handleSidebarToggle} />
                <div className="page-wrapper">
                  <Navigation handleSidebarToggle={this.handleSidebarToggle} />
                  <div className="page-scroll">
                    <div className="flex-fill page-fix">
                      <div className="m-3">
                        <div className="container-fluid">
                          <GuardedRoute path="/history" component={ShareHistory} meta={{ auth: true }} />
                        </div>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </main>
            </GuardedRoute>
            <GuardedRoute exact path="/configurations">
              <main className={`page theme-dark ${toggled ? "" : "toggled"}`}>
                <Header handleSidebarToggle={this.handleSidebarToggle} />
                <div className="page-wrapper">
                  <Navigation handleSidebarToggle={this.handleSidebarToggle} />
                  <div className="page-scroll">
                    <div className="flex-fill page-fix">
                      <div className="m-3">
                        <div className="container-fluid">
                          <GuardedRoute path="/configurations" component={ConfigurationList} meta={{ auth: true }} />
                        </div>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </main>
            </GuardedRoute>
            <GuardedRoute exact component={SetPassword} path="/users/setpassword" />
            <GuardedRoute component={NotFound} path="*" />
          </Switch>
        </GuardProvider>
        <Modal className="sessionModal" backdrop="static" show={showTimeoutModal} onHide={this.onCancleSession}>
          <SessionTimeoutModal onResetSession={this.onResetSession} onCancleSession={this.onCancleSession} />
        </Modal>
      </Router>
    );
  }
}
export default withRouter(MainController);
