import axios from "axios";
import configData from "../config/config";

// Method use to make api request to aws
const getConfigurations = async function (pageSize, pageToken = "", configName = "") {
  // TODO: Logging framework to be added in future.
  let url = `${configData.API_URL}/configs?pageSize=${pageSize}`;
  let val = "";
  if (configName && pageToken) {
    val = configName;
    url = `${url}&configName=${encodeURIComponent(val)}&id=${pageToken}`;
  } else if (pageToken) {
    url = `${url}&id=${pageToken}`;
  } else if (configName) {
    val = configName;
    url = `${url}&configName=${encodeURIComponent(val)}`;
  }
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

const getReportData = async (configId) => {
  const url = `${configData.API_URL}/configs/${configId}/calculate`;
  const res = await axios({
    method: "POST",
    url,
  });
  return res;
};
const getSystemTypes = async () => {
  const url = `${configData.API_URL}/standards`;
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

// used for get system types from the localstorage
const getStandardListFromLocalStorage = () => {
  if (localStorage.key("standardList")) {
    return JSON.parse(localStorage.getItem("standardList"));
  }
  return null;
};

// used for get system types from the localstorage
const getFooterListFromLocalStorage = () => {
  if (localStorage.key("footerList")) {
    return JSON.parse(localStorage.getItem("footerList"));
  }
  return null;
};
const ConfigService = {
  getConfigurations,
  getReportData,
  getSystemTypes,
  getStandardListFromLocalStorage,
  getFooterListFromLocalStorage,
};

export default ConfigService;
