import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";

// Services
import authService from "../../../services/AuthService";
import UserService from "../../../services/UserService";
import commonUtiles from "../../../services/CommonUtiles";
import configData from "../../../config/config";
import ChangesWarningModal from "../../Common/ChangesWarningModal";

// Create/Update User Component
const CreateUpdateUser = () => {
  const history = useHistory();
  const { SUCCESS_MESSAGES } = configData;
  const { id } = useParams();
  const isAddMode = !id;
  const [isLoaded, setIsLoaded] = useState(false);
  const roles = [
    { value: "admin", label: "Admin" },
    { value: "user", label: "User" },
  ];
  const [role, setRole] = useState(roles[1]);
  const [isDirty, setIsDirty] = useState(false);
  const [changeRequest, setChangeRequest] = useState(false);
  const [isSelf, setIsSelf] = useState(false);

  const { INFO_MESSAGE } = configData;
  const nameWarningMessage = "Please enter valid characters only (English alphabets, space and hyphen)";

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid Email Address (Format abc@xyz.com)")
      .required("Email Address is Required")
      .min(5, "Please enter valid Email Address (Format abc@xyz.com)")
      .max(128, "Please enter valid Email Address (Max 128 character allowed in format abc@xyz.com"),
    name: Yup.string()
      .required("First Name is required")
      .max(30, "Please enter valid First Name (Maximum 30 character allowed)")
      .matches(/[^- ](.*)$/, nameWarningMessage)
      .matches(/^[a-zA-Z -]+$/, nameWarningMessage),
    lastName: Yup.string()
      .required("Last Name is required")
      .max(30, "Please enter valid Last Name (Maximum 30 character allowed)")
      .matches(/[^- ](.*)$/, nameWarningMessage)
      .matches(/^[a-zA-Z -]+$/, nameWarningMessage),
    company: Yup.string()
      .required("Company Name is required")
      .max(30, "Please enter valid Company Name (Maximum 30 character allowed)")
      .matches(
        /^[a-zA-Z-'._,0-9/ ]*$/,
        "Please enter valid Company Name (Allowed English character, numbers and ' , _ . - / )"
      )
      .matches(/^(?=.*[A-Za-z])[a-zA-Z-'._,0-9/ ]*$/, "Please enter valid Company Name (Minimum 1 letter required)"),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, setValue, errors, formState, setError } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // function invokes on create user button click
  const createUser = function (data) {
    const rawData = data;
    rawData.role = role.value;
    return UserService.createUserservice(rawData)
      .then(() => {
        commonUtiles.displayNotification(SUCCESS_MESSAGES.USER_CREATED, "success");
        history.push("/users");
        setIsDirty(false);
        sessionStorage.setItem("isDirty", JSON.stringify(false));
      })
      .catch((error) => {
        commonUtiles.displayErrorMessage(error);
        if (error?.response?.data?.ERROR_MESSAGE_CODE === "USER_EMAIL_ID_EXISTS_EXCEPTION") {
          setError("email", {
            type: "manual",
            message: error?.response?.data?.MESSAGE,
          });
        }
      });
  };

  // function invokes on save changes button click in Edit User Screen
  const updateUser = function (data) {
    const rawData = data;
    rawData.role = role.value;
    return UserService.updateUserservice(rawData)
      .then(() => {
        commonUtiles.displayNotification(SUCCESS_MESSAGES.USER_UPDATED, "success");
        history.push("/users");
        setIsDirty(false);
        sessionStorage.setItem("isDirty", JSON.stringify(false));
      })
      .catch((error) => {
        commonUtiles.displayErrorMessage(error);
      });
  };

  // function use to submit form data
  function onSubmit(data) {
    return isAddMode ? createUser(data) : updateUser(data);
  }
  const [user, setUser] = useState({});

  const checkForSelf = () => setTimeout(() => setIsSelf(authService.getUserName() === decodeURIComponent(id)), 1000);

  useEffect(() => {
    if (!isAddMode) {
      checkForSelf();
      setIsLoaded(true);
      // get user and set form fields
      UserService.getById(id)
        .then((response) => {
          setIsLoaded(false);
          const fields = ["email", "name", "lastName", "company", "role"];
          fields.forEach((field) => setValue(field, response.data.user[field]));
          setUser(user);
          setRole(response.data.user.role === "user" ? roles[1] : roles[0]);
        })
        .catch((error) => {
          commonUtiles.displayErrorMessage(error);
          history.push("/users");
          setIsLoaded(false);
        });
    }
  }, []);
  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setRole(e);
    setIsDirty(true);
    sessionStorage.setItem("isDirty", JSON.stringify(true));
  };

  const redirectToUserPage = () => {
    if (isDirty) {
      setChangeRequest(true);
    } else {
      history.push("/users");
    }
  };
  const onChange = () => {
    setIsDirty(true);
    sessionStorage.setItem("isDirty", JSON.stringify(true));
  };

  const closeChangesWarningModal = (status) => {
    if (status) {
      setChangeRequest(false);
    } else {
      setIsDirty(false);
      sessionStorage.setItem("isDirty", JSON.stringify(false));
      history.push("/users");
    }
  };
  return (
    <>
      <Modal
        className="saveasModal"
        backdrop="static"
        tabindex="-1"
        show={changeRequest}
        onHide={closeChangesWarningModal}
        centered
      >
        <ChangesWarningModal
          closeChangesWarningModal={closeChangesWarningModal}
          message={INFO_MESSAGE.UNSAVED_CHANGES_CONFM}
        />
      </Modal>
      <div className="row">
        <div className="col-auto mx-auto">
          <form name="userForm" onSubmit={handleSubmit(onSubmit)} onChange={() => onChange()}>
            <Card className="bg-gray-dark rounded-md mt-7" style={{ width: "600px", padding: "50px 87px" }}>
              <Card.Body className="p-0">
                <div className="d-flex align-items-center changepass-title">
                  <h2 className="text-white mb-0">
                    {isAddMode ? "Create User" : "Edit User"}
                    {isLoaded ? (
                      <span className="spinner-border spinner-border-sm ml-3" style={{ marginBottom: "4px" }} />
                    ) : (
                      ""
                    )}
                  </h2>
                  <div className="close-icon text-right mb-5">
                    <button type="button" aria-label="close" className="close" onClick={redirectToUserPage}>
                      <span aria-hidden="true">
                        <i className="fa fa-times text-red" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="user-fgr">
                  <label className="form-label">Email Address</label>
                  <input
                    type="text"
                    name="email"
                    readOnly={!isAddMode}
                    ref={register}
                    className={`form-control ${errors.email ? "is-invalid" : ""}
                      ${!isAddMode ? "curser-na muted" : null}
                      user-input`}
                    autoComplete="off"
                    maxLength={128}
                    style={{ "text-transform": "lowercase" }}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                </div>

                <div className="user-fgr">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    name="name"
                    ref={register}
                    className={`form-control ${errors.name ? "is-invalid" : ""} user-input`}
                    autoComplete="off"
                    maxLength={30}
                  />
                  {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                </div>

                <div className=" user-fgr">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    ref={register}
                    className={`form-control ${errors.lastName ? "is-invalid" : ""} user-input`}
                    autoComplete="off"
                    maxLength={30}
                  />
                  {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                </div>

                <div className="user-fgr">
                  <label className="form-label">Company Name</label>
                  <input
                    type="text"
                    name="company"
                    ref={register}
                    className={`form-control ${errors.company ? "is-invalid" : ""} user-input`}
                    autoComplete="off"
                    maxLength={30}
                  />
                  {errors.company && <div className="invalid-feedback">{errors.company.message}</div>}
                </div>

                <div className="mb-4">
                  <label className="form-label">Role</label>
                  <Select
                    onChange={handleChange}
                    options={roles}
                    name="role"
                    ref={register}
                    className={` ${errors.role ? "is-invalid" : ""} user-input custom-i-addon`}
                    autoFocus={false}
                    isSearchable={false}
                    value={role}
                    styles={commonUtiles.getDropDownStyle(isSelf)}
                    isDisabled={isSelf}
                  />
                  <div className="invalid-feedback">{errors.role?.message}</div>
                </div>
              </Card.Body>

              <div className="card-footer text-center mt-3">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-red">
                  {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1" />}
                  {isAddMode ? "Create User" : "Save Changes"}
                </button>
              </div>
            </Card>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateUpdateUser;
