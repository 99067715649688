import React from "react";
// Spinner
const Spinner = () => (
  <>
    <div className="dimmer h-9 active">
      <div className="loader" />
      <div className="dimmer-content" />
    </div>
  </>
);

export default Spinner;
