// API url for production environment
const prod = {
  API_URL: "https://api.aog.whelen.com",
};

// API url for staging environment
const staging = {
  API_URL: "https://staging-api.aog.whelen.com",
};

// API url for dev environment
const dev = {
  API_URL: "https://dev-api.aog.whelen.com",
};

// API url for test environment
const test = {
  API_URL: "https://dev-api.aog.whelen.com",
};

// Set value of config variable as per the value of env variable REACT_APP_STAGE
const config =
  process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : process.env.REACT_APP_STAGE === "test"
    ? test
    : dev;

export default {
  ERROR_MESSAGES: {
    UNAUTHORIZED_REQUEST: "Wrong Email Address or Password. Try again or click on Trouble logging in.",
    ACCESS_DENIED: "Wrong Email Address or Password. Try again or click on Trouble logging in.",
    UNAUTHORIZED_USER: "Your account has been disabled. Please contact Whelen for assistance",
    USER_BAD_REQUEST: "Required parameters are missing.",
    SOMETHING_WENT_WRONG: "Something went wrong. Please try later.",
    INCORRECT_CURRENT_PASSWORD: "Incorrect Current Password",
    LIMIT_EXCEEDED_EXCEPTION: "Your attempts to change password are completed. Please try after sometime.",
    ID_TOKEN_EXPIRED: "Session is expired. Please login again.",
    USER_TOKEN_EXPIRED:
      "Link to reset the password is expired. Please click on 'Trouble Logging In?' to reset the password.",
    USER_INVALID_INFO: "Process or URL is interrupted. Please try again with valid URL",
    USER_INVALID_TOKEN:
      "New Password has already been updated. If you are facing problem with login, please click on 'Trouble Logging In?' to reset the password.",
  },
  SUCCESS_MESSAGES: {
    USER_CREATED: "User is created successfully",
    USER_UPDATED: "User is updated successfully",
    PASSWORD_UPDATED: "Password updated successfully",
    PASSWORD_SET: "Password set successfully.",
    TROUBLE_LOGIN_EMAIL_SENT: "Email has been sent to your account.",
  },
  INFO_MESSAGE: {
    UNSAVED_CHANGES_CONFM: "Are you sure, you want to discard the changes?",
  },
  SESSION_TIMEOUT: 30, // minutes,
  SESSION_NOTIFICATION_TIMEOUT: 30, // seconds
  ...config,
};
