import axios from "axios";
import configData from "../config/config";

// Method use to make login api request to aws
const login = async (data) => {
  const email = data.email.toLowerCase();
  const url = `${configData.API_URL}/auth/admin/login`;
  const res = await axios({
    method: "POST",
    headers: {
      "x-login": email,
      "x-password": data.password,
    },
    url,
  });
  return res;
};
// Method use to make logout api request to aws
const logout = async () => {
  const url = `${configData.API_URL}/auth/logout`;
  const res = await axios({
    method: "POST",
    url,
  });
  return res;
};

const setLocalStorage = (response, request) => {
  localStorage.setItem("loggedIn", JSON.stringify(true));
  localStorage.setItem("firstName", JSON.stringify(response.firstName));
  localStorage.setItem("lastName", JSON.stringify(response.lastName));
  localStorage.setItem("userId", JSON.stringify(response.userId));
  localStorage.setItem("role", JSON.stringify(response.role));
  localStorage.setItem("idToken", JSON.stringify(response.idToken));
  localStorage.setItem("refreshToken", JSON.stringify(response.refreshToken));
  localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
  localStorage.setItem("userName", JSON.stringify(request.email));
};

const getName = () => {
  if (localStorage.key("firstName" || "lastName")) {
    let name = `${JSON.parse(localStorage.getItem("firstName"))} `;
    name += JSON.parse(localStorage.getItem("lastName"));
    return name;
  }
  return "";
};
const getRole = () => {
  if (localStorage.key("role")) {
    return JSON.parse(localStorage.getItem("role"));
  }
  return "";
};
// Get UserName from localstorage
const getUserName = () => {
  if (localStorage.key("userName")) {
    return JSON.parse(localStorage.getItem("userName"));
  }
  return "";
};

// Get AccessToken from the localstorage
const getAccessToken = () => {
  if (localStorage.key("accessToken")) {
    return JSON.parse(localStorage.getItem("accessToken"));
  }
  return "";
};
// Get IdToken from the localstorage
const getIdToken = () => {
  if (localStorage.key("idToken")) {
    return JSON.parse(localStorage.getItem("idToken"));
  }
  return "";
};

const getRefreshedTokens = async () => {
  const data = {};
  data.refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
  const url = `${configData.API_URL}/auth/refresh-token`;
  const res = await axios({
    method: "POST",
    url,
    data,
  });
  return res;
};

// Check user is logged i or not frrom the localstorage
const isUserLoggedin = () => {
  if (localStorage.key("loggedIn")) {
    return JSON.parse(localStorage.getItem("loggedIn"));
  }
  return "";
};

// Check user request is unotherised or not from the localstorage
const getErrorMessage = () => {
  if (localStorage.key("errorMessage")) {
    return JSON.parse(localStorage.getItem("errorMessage"));
  }
  return "";
};

const getIsDirty = () => {
  if (localStorage.key("isDirty")) {
    return JSON.parse(sessionStorage.getItem("isDirty"));
  }
  return false;
};
// Method use to make change password api request to aws
const changePassword = async (data) => {
  const url = `${configData.API_URL}/users/change-password`;
  const res = await axios({
    method: "POST",
    url,
    data,
  });
  return res;
};

// Method use to make set password api request to aws
const setPassword = async (data) => {
  const url = `${configData.API_URL}/users/set-password`;
  const res = await axios({
    method: "POST",
    url,
    data,
  });
  return res;
};
// Method use to make forget password api request to aws
const forgotPassword = async (data) => {
  /* eslint-disable no-param-reassign */
  data.email = data.email.toLowerCase();
  const url = `${configData.API_URL}/users/forgot-password`;
  const res = await axios({
    method: "POST",
    url,
    data,
  });
  return res;
};
const isTokenExpired = () => {
  const jwt = JSON.parse(atob(getAccessToken()?.split(".")[1]));
  const jwtExp = jwt && jwt.exp && jwt.exp * 1000;
  return Date.now() + 300000 > jwtExp;
};

const AuthService = {
  login,
  setLocalStorage,
  logout,
  getName,
  getRole,
  changePassword,
  forgotPassword,
  getUserName,
  getAccessToken,
  isUserLoggedin,
  getErrorMessage,
  getIdToken,
  setPassword,
  getRefreshedTokens,
  isTokenExpired,
  getIsDirty,
};

export default AuthService;
