import { toast } from "react-toastify";
import configData from "../config/config";
import configService from "./ConfigService";
// Set Arrow direction as per sorting order
const setArrow = (sort, column) => {
  let className = "sort-direction";

  if (sort.column === column) {
    className += sort.direction === "asc" ? " asc" : " desc";
  } else {
    className += " default";
  }
  return className;
};

// Convert epoch into local date with format "MM/DD/YY HH:MM"
const formatDate = (epoch) => {
  const date = new Date(epoch);
  const yy = parseInt(date.getFullYear().toString().substr(2, 2), 10);
  const rawDate = date.toLocaleString("en-US", { hour12: false }).replace(",", "").split(" ");
  const timePart = `${rawDate[1].split(":")[0]}:${rawDate[1].split(":")[1]}`;
  const mm = rawDate[0].split("/")[0].length === 1 ? `0${rawDate[0].split("/")[0]}` : rawDate[0].split("/")[0];
  const dd = rawDate[0].split("/")[1].length === 1 ? `0${rawDate[0].split("/")[1]}` : rawDate[0].split("/")[1];
  const datePart = `${mm}/${dd}/${yy}`;
  return `${datePart} ${timePart}`;
};

// Convert epoch into local date with format "MM/DD/YYYY"
const getDateForPDF = (epoch) => {
  const date = new Date(epoch);
  const rawDate = date.toLocaleString("en-US", { hour12: false }).replace(",", "").split(" ");
  return rawDate[0];
};

// Compares two values
const compareValues = (a, b, column, direction) => {
  const nameA = a[column]?.toString().toUpperCase(); // ignore upper and lowercase
  const nameB = b[column]?.toString().toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB || (nameA === nameB && direction === "asc")) {
    return -1;
  }
  if (nameA > nameB || (nameA === nameB && direction === "desc")) {
    return 1;
  }
  if (direction === "asc") {
    return -1;
  }
  return 1;
};

const displayNotification = (message, type) => {
  toast(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type,
    className: "toaster",
  });
};

// display error message for the api call
const displayErrorMessage = (errorObject) => {
  const { ERROR_MESSAGES } = configData;
  if (errorObject && errorObject?.response) {
    const errorResponse = errorObject?.response;
    if (errorResponse?.status.toString().startsWith("5")) {
      displayNotification(ERROR_MESSAGES.SOMETHING_WENT_WRONG, "error");
    } else {
      const ERROR_MESSAGE_CODE = errorResponse?.data?.ERROR_MESSAGE_CODE;
      if (ERROR_MESSAGE_CODE && ERROR_MESSAGES[ERROR_MESSAGE_CODE]) {
        displayNotification(ERROR_MESSAGES[ERROR_MESSAGE_CODE], "error");
      } else if (ERROR_MESSAGE_CODE) {
        displayNotification(errorResponse?.data?.MESSAGE, "error");
      } else {
        displayNotification(ERROR_MESSAGES.SOMETHING_WENT_WRONG, "error");
      }
    }
  } else {
    displayNotification(ERROR_MESSAGES.SOMETHING_WENT_WRONG, "error");
  }
};
const getDropDownStyle = (isDisabled) => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      height: 31,
      minHeight: 31,
      backgroundColor: isDisabled ? "dimgrey" : "white",
      color: isDisabled ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return style;
};
const getDropDownStyleForPage = () => {
  const style = {
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      height: 35,
      minHeight: 35,
      cursor: "pointer",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 2,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      height: 100,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return style;
};

const getPageSizes = () => [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];
const getStdLabel = (value) => {
  const stdList = configService.getStandardListFromLocalStorage();
  const standard = stdList.filter((std) => std.value === value);
  return standard.length ? standard[0].label : value;
};
const getfooterText = (value) => {
  const footerList = configService.getFooterListFromLocalStorage();
  const footer = footerList.filter((text) => text.value === value);
  return footer.length ? footer[0].label : value;
};
const CommonUtiles = {
  setArrow,
  formatDate,
  compareValues,
  displayNotification,
  displayErrorMessage,
  getDropDownStyle,
  getDateForPDF,
  getPageSizes,
  getDropDownStyleForPage,
  getStdLabel,
  getfooterText,
};

export default CommonUtiles;
// }
