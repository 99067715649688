import axios from "axios";

import authService from "../services/AuthService";

const checkUrlNRequest = (request) => {
  if (request.url.includes("logout")) {
    request.headers.Authorization = authService.getAccessToken();
  } else {
    request.headers.Authorization = authService.getIdToken();
  }
  return request;
};
// Request interceptor for API calls
axios.interceptors.request.use(
  async (request) => {
    request.headers["X-Requested-With"] = "XMLHttpRequest";
    request.headers["Content-Type"] = "application/json";
    if (
      !(
        request.url.includes("refresh-token") ||
        request.url.includes("login") ||
        request.url.includes("set-password") ||
        request.url.includes("forgot-password") ||
        request.url.includes("request-access")
      )
    ) {
      if (authService.isTokenExpired()) {
        const tokens = await authService.getRefreshedTokens();
        localStorage.setItem("idToken", JSON.stringify(tokens.data.IdToken));
        localStorage.setItem("accessToken", JSON.stringify(tokens.data.AccessToken));
        return checkUrlNRequest(request);
      }
      return checkUrlNRequest(request);
    }
    return request;
  },
  (error) => Promise.reject(error)
);

// Request interceptor for response
axios.interceptors.response.use(undefined, (error) => {
  if (error?.response?.data?.message === "Unauthorized" || error?.response?.data?.message === "Forbidden") {
    window.location.href = "/login";
    localStorage.setItem("errorMessage", JSON.stringify(error.response.status));
  }
  return Promise.reject(error);
});
