// component for forget password
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";

// Services
import AuthService from "../../services/AuthService";
import commonUtiles from "../../services/CommonUtiles";
import configData from "../../config/config";

const ForgotPassword = ({ closeForgotPasswordModal }) => {
  const { SUCCESS_MESSAGES } = configData;
  const [isLoading, setIsLoading] = useState(false);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid Email Address (Format abc@xyz.com)")
      .required("Email Address is required")
      .min(5, "Please enter valid Email Address (Format abc@xyz.com)")
      .max(128, "Please enter valid Email Address (Max 128 character allowed in format abc@xyz.com"),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  // uses to call the login API, clicked on sign in button
  const onSubmit = (data) => {
    setIsLoading(true);
    AuthService.forgotPassword(data)
      .then(() => {
        closeForgotPasswordModal();
        commonUtiles.displayNotification(SUCCESS_MESSAGES.TROUBLE_LOGIN_EMAIL_SENT, "success");
        setIsLoading(false);
      })
      .catch((error) => {
        closeForgotPasswordModal();
        commonUtiles.displayErrorMessage(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="mg-left">
        <div className="changepassword">
          <div className="changepass-block">
            <div className="d-flex align-items-center changepass-title">
              <h2 className="text-white mb-0">Trouble Logging In?</h2>
              <div className="close-icon text-right mb-5">
                <button
                  type="button"
                  className="close"
                  aria-label="forgot password"
                  data-dismiss="modal"
                  onClick={closeForgotPasswordModal}
                >
                  <span aria-hidden="true">
                    <i className="fa fa-times text-red" />
                  </span>
                </button>
              </div>
            </div>
            <div className="desc mb-7">
              To reset your password enter your email address and a message will be sent with instructions. If you
              don&#39;t know your email address please contact your Whelen Account Manager.
            </div>
            <form className="troublelogin-form" action="" method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="row mg-bt-40 user-fg">
                <label className="col-sm-4 col-form-label">Email Address</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="email"
                    ref={register}
                    className={`form-control ${errors.email ? "is-invalid" : ""} user-input`}
                    autoComplete="off"
                    spellCheck="false"
                    maxLength={128}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                </div>
              </div>
              <div className="d-flex justify-content-center mb-10">
                <button type="submit" className={`btn btn-red mt-0 mr-3 close-first ${isLoading ? "cursor-auto" : ""}`}>
                  {isLoading ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
