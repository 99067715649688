// Login screen
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import jwt from "jwt-decode";
// Media Components
import BrandIcon from "../../assets/img/logo.svg";
import logoImg from "../../assets/img/logo-white.svg";
// Services
import AuthService from "../../services/AuthService";
import commonUtiles from "../../services/CommonUtiles";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import Footer from "../Common/LoginFooter";

const Login = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [password, setPassword] = useState("");
  const [isForceLogin, setIsForceLogin] = useState(false);
  const [token, setToken] = useState("");
  const [uid, setUid] = useState("");
  const [openModal, setModalVisibility] = useState(false);
  useEffect(() => {
    if (AuthService.isUserLoggedin()) {
      localStorage.clear();
      localStorage.setItem("loggedIn", JSON.stringify(false));
    }
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid Email Address (Format abc@xyz.com)")
      .required("Email Address is required")
      .min(5, "Please enter valid Email Address (Format abc@xyz.com)")
      .max(128, "Please enter valid Email Address (Max 128 character allowed in format abc@xyz.com"),
    password: Yup.string()
      .trim()
      .required("Password is required")
      .max(256, "Please enter valid Password (Max 256 character allowed"),
  });

  // functions to build form returned by useForm() hook
  const { register, reset, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // used for open set-password modal
  const openSetPasswordModal = () => {
    setIsForceLogin(true);
  };

  // used for close set-password modal
  const closeSetPasswordModal = () => {
    setIsForceLogin(false);
  };

  // uses to call the login API, clicked on sign in button
  const onSubmit = (data) => {
    setIsLoading(true);
    data.password.trim();
    AuthService.login(data)
      .then((response) => {
        reset();
        setIsLoading(false);
        if (response.data.userStatus === "FORCE_CHANGE_PASSWORD") {
          setToken(response.data.token.toString());
          setUid(response.data.userId);
          openSetPasswordModal();
        } else {
          const resData = response.data;
          // decode idToken
          const decoded = jwt(resData.idToken);
          Object.keys(decoded).forEach((key) => {
            if (key.includes(":")) {
              const [, custumAttribute] = key.split(":");
              resData[custumAttribute] = decoded[key];
            } else if (key === "family_name") {
              resData.lastName = decoded.family_name;
            } else if (key === "name") {
              resData.firstName = decoded.name;
            } else if (key === "sub") {
              resData.userId = decoded.sub;
            } else {
              resData[key] = decoded[key];
            }
          });
          AuthService.setLocalStorage(resData, data);
          history.push("/users");
        }
      })
      .catch((error) => {
        commonUtiles.displayErrorMessage(error);
        setIsLoading(false);
      });
  };
  // placeholder to solve eslint error
  const handleClick = () => {};

  // toggle the password view icon
  const togglePwd = () => {
    setIsHidden(!isHidden);
  };

  // set the password on change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // used for open forgot-password modal
  const openForgotPasswordModal = () => {
    setModalVisibility(true);
  };

  // used for close forgot-password modal
  const closeForgotPasswordModal = () => {
    setModalVisibility(false);
  };
  return (
    <>
      <div className="page-single login theme-dark">
        <div className="container">
          <div className="row flex-column">
            <div className="col col-login mx-auto">
              <div className="card bg-gray-dark rounded-md login-card">
                <div className="card-body py-8 login-pd">
                  <div className="text-center mb-7">
                    <a href="" aria-label="icon">
                      <img src={BrandIcon} className="cursor-auto logo-size" alt="" />
                    </a>
                  </div>
                  <div className="card-title mb-6">AOG Admin console</div>
                  <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group user-fg">
                      <label className=" txt-trans">Email Address</label>
                      <input
                        type="text"
                        name="email"
                        ref={register}
                        className={`form-control ${errors.email ? "is-invalid" : ""} user-input`}
                        autoComplete="off"
                        spellCheck="false"
                        maxLength={128}
                        style={{ "text-transform": "lowercase" }}
                      />
                      {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                    </div>
                    <div className="form-group user-fg">
                      <label className="txt-trans">Password</label>
                      <div className="pos-relative">
                        <input
                          type={isHidden ? "password" : "text"}
                          name="password"
                          ref={register}
                          className={`form-control ${errors.password ? "is-invalid" : ""} user-input`}
                          onChange={handlePasswordChange}
                          autoComplete="off"
                          maxLength={256}
                        />
                        {password && !errors.password ? (
                          <button
                            type="button"
                            aria-label="button"
                            className="input-icon-addon custom-i-addon toggle-pwd"
                          >
                            <i
                              aria-hidden="true"
                              className={`far ${isHidden ? "fa-eye-slash" : "fa-eye"}`}
                              onClick={togglePwd}
                              onKeyDown={handleClick}
                              role="button"
                              tabIndex={0}
                            />
                          </button>
                        ) : null}
                        {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                      </div>
                    </div>
                    <div className="form-footer login-btn">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`btn btn-block login-btn-in txt-trans ${isLoading ? "cursor-auto" : ""}`}
                      >
                        {isLoading ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                        Log in
                      </button>
                    </div>
                  </form>
                  <p className="text-center mb-0 mt-6">
                    <a href="#" tabIndex="-1" className="text-white link-button" onClick={openForgotPasswordModal}>
                      Trouble Logging In?
                    </a>
                  </p>
                </div>
                <div className="text-center mb-7">
                  <a href="https://www.whelen.com/" aria-label="logo" rel="noreferrer" target="_blank">
                    <img src={logoImg} className="power-logo" alt="" />
                  </a>
                </div>
                <Modal
                  className="change-password-modal"
                  centered
                  backdrop="static"
                  tabindex="-1"
                  show={openModal}
                  onHide={closeForgotPasswordModal}
                >
                  <ForgotPassword closeForgotPasswordModal={closeForgotPasswordModal} />
                </Modal>
                <Modal
                  className="change-password-modal"
                  centered
                  backdrop="static"
                  keyboard={false}
                  show={isForceLogin && !!token && !!uid}
                  onHide={closeSetPasswordModal}
                >
                  <ChangePassword
                    closeChangePasswordModal={closeSetPasswordModal}
                    token={token}
                    uid={uid}
                    isForceLogin
                  />
                </Modal>
              </div>
            </div>
          </div>
          <Footer loginpage="true" />
        </div>
      </div>
    </>
  );
};
export default Login;
